import config from 'config';
import { isLinodeUploadEnabled } from './flags';

export function getS3PublicUri(object: string, provider: string): string {
    if (isLinodeUploadEnabled(provider)) {
        return `s3://${config.linode.region}/${config.linode.bucket}/${object}`;
    }
    return `s3://${config.aws.region}/${config.aws.bucket}/${object}`;
}

export function generateS3BucketPath(provider: string, file: File, scope: string): string {
    const date = new Date();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const timestamp = +date;
    const [, randomNum = '0'] = String(Math.random()).split('.');

    return `${provider}/${scope}/${year}/${month}/${timestamp}-${randomNum}-${file.type.replace('/', '-')}`;
}
