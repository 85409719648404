import { put, select, takeEvery, take, race, call } from '@redux-saga/core/effects';
import { prepareFiltersReverse } from 'models/filter';
import { generateSearchParametersReverse } from 'store/utils';
import { TAGS_FETCH_SUCCESS, TAGS_FETCH_ERROR } from 'store/tags/actionTypes';
import { reportMessageToSentry } from 'lib/error';

import { getAccessDefinitionOptionsQueryState } from 'services/svp-api-client/access-definitions';
import { getTags, getMissingTags } from 'store/tags/selectors';
import { fetchTags } from 'store/tags/actions';
import { PLAYLISTS_FETCH_SUCCESS } from 'store/playlists/items';
import { PLAYLISTS_FILTERS_SET_CONTROLLER, playlistsFiltersOverride } from 'store/playlists/filters';

import { getPlaylist, isPlaylistLoaded } from 'store/playlists/selectors';
import { getCategories } from 'store/categories/selectors';
import { fetchCategory } from 'store/categories/sagas';
import { adminBffSdk } from 'services/admin-bff-sdk';

export function* fetchTagsForPlaylist({ provider, playlistTagsIds }) {
    try {
        if (!playlistTagsIds.length) {
            return;
        }

        const ids = yield select((state) => getMissingTags(state, provider, playlistTagsIds));
        yield put(fetchTags({ ids, provider }));

        const shouldWaitForTags = playlistTagsIds && ids.length;
        if (shouldWaitForTags) {
            yield race({
                success: take(
                    (action) =>
                        action.type === TAGS_FETCH_SUCCESS &&
                        action.tags.every((tag) => playlistTagsIds.includes(tag.id))
                ),
                error: take(
                    (action) =>
                        action.type === TAGS_FETCH_ERROR && action.tags.every((tag) => playlistTagsIds.includes(tag.id))
                ),
            });
        }
    } catch (error) {
        reportMessageToSentry({
            message: 'Failed to fetch missing tags',
            extras: {
                error,
            },
        });
    }
}

export function* fetchStoriesForPlaylist({ provider, playlistStoriesIds }) {
    try {
        if (!playlistStoriesIds) {
            return undefined;
        }

        const getStoriesPromise = yield put(
            adminBffSdk.endpoints.getStories.initiate({ provider, ids: playlistStoriesIds })
        );
        const { data, error } = yield getStoriesPromise;

        if (error) {
            throw new Error(error);
        }

        return Object.fromEntries((data?.getStories || []).map(({ id, title }) => [id, title]));
    } catch (error) {
        reportMessageToSentry({
            message: 'Failed to fetch missing stories',
            extras: {
                error,
            },
        });
        return undefined;
    }
}

export function* setFiltersController({ payload: { provider, playlistId } }) {
    const shouldWaitForPlaylist = yield select((state) => !isPlaylistLoaded(state, provider, playlistId));

    if (shouldWaitForPlaylist) {
        yield take(PLAYLISTS_FETCH_SUCCESS);
    }

    const playlist = yield select((state) => getPlaylist(state, provider, playlistId));
    const searchQuery = new URLSearchParams(playlist.searchQuery || '');
    const before = Number(searchQuery.get('before'));
    const after = Number(searchQuery.get('after'));
    const newsrooms = searchQuery.get('searchQueryProviders');
    const streamType = ['wasLive', 'vod'];

    const preparedFilters = { ...prepareFiltersReverse(searchQuery.get('filter') || ''), streamType };
    const allTags = [preparedFilters?.['additional.tags.id'], preparedFilters?.excludedTags].flat().filter(Boolean);

    const { categoryId, excludedCategory } = preparedFilters;
    if (categoryId) {
        yield call(fetchCategory, { payload: { provider, categoryId } });
    }
    if (excludedCategory?.length) {
        for (const excludedId of excludedCategory) {
            yield call(fetchCategory, { payload: { provider, categoryId: excludedId } });
        }
    }
    const categories = yield select((state) => getCategories(state, { provider }));

    yield call(fetchTagsForPlaylist, {
        provider,
        playlistTagsIds: allTags,
    });
    const tags = yield select((state) => getTags(state, provider) || {});
    const stories = yield call(fetchStoriesForPlaylist, {
        provider,
        playlistStoriesIds: preparedFilters?.['additional.stories'],
    });

    const accessDefinitionOptions = yield select(getAccessDefinitionOptionsQueryState(provider));

    const filters = {
        ...generateSearchParametersReverse(
            provider,
            newsrooms,
            preparedFilters,
            tags,
            stories,
            categories,
            accessDefinitionOptions
        ),
        ...(before && { before }),
        ...(after && { after }),
    };
    yield put(playlistsFiltersOverride({ filters }));
}

export default [takeEvery(PLAYLISTS_FILTERS_SET_CONTROLLER, setFiltersController)];
