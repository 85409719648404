import { isLinodeUploadEnabled } from 'lib/flags';
import { EvaporateFileUploader } from './evaporate-file-uploader';
import { AWSFileUploader } from './aws-file-uploader';
import { FileUploaderEmitter } from './file-uploader-emitter';
import type { UploadData, UploadWithoutProgressData } from './types';

export default class FileUploader extends FileUploaderEmitter {
    evaporate: EvaporateFileUploader;

    awsUpload: AWSFileUploader;

    constructor() {
        super(new Map());

        this.evaporate = new EvaporateFileUploader(this.eventHandlers);
        this.awsUpload = new AWSFileUploader(this.eventHandlers);
    }

    static async create() {
        return new FileUploader();
    }

    registerHandler(event: symbol, handler: () => void) {
        this.eventHandlers.set(event, handler);
    }

    async uploadFile(params: UploadData) {
        if (isLinodeUploadEnabled(params.provider)) {
            return this.awsUpload.uploadFile(params);
        }
        return this.evaporate.uploadFile(params);
    }

    async cancelUploadFile({ provider, filename }: { provider: string; filename: string }): Promise<void> {
        if (isLinodeUploadEnabled(provider)) {
            return this.awsUpload.cancelUploadFile(filename);
        }
        return this.evaporate.cancelUploadFile(filename);
    }

    async uploadFileWithoutProgress({ provider, ...params }: UploadWithoutProgressData & { provider: string }) {
        if (isLinodeUploadEnabled(provider)) {
            return this.awsUpload.uploadFileWithoutProgress(params);
        }
        return this.evaporate.uploadFileWithoutProgress(params);
    }
}
