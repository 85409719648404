import type { EventHandler, UploadCompleteData, UploadErrorData, UploadProgressData } from './types';

export type EventHandlers = Map<symbol, EventHandler>;

export class FileUploaderEmitter {
    static EVENT_PROGRESS = Symbol('progress');

    static EVENT_COMPLETE = Symbol('complete');

    static EVENT_ERROR = Symbol('error');

    protected eventHandlers: EventHandlers;

    constructor(eventHandlers: EventHandlers) {
        this.eventHandlers = eventHandlers;
    }

    protected onComplete(data: UploadCompleteData) {
        this.eventHandlers.get(FileUploaderEmitter.EVENT_COMPLETE)?.(data);
    }

    protected onError(data: UploadErrorData) {
        this.eventHandlers.get(FileUploaderEmitter.EVENT_ERROR)?.(data);
    }

    protected onProgress(data: UploadProgressData) {
        this.eventHandlers.get(FileUploaderEmitter.EVENT_PROGRESS)?.(data);
    }
}
