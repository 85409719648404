import config from 'config';
import { S3ClientProxyApi } from 'services/file-uploader/s3client-proxy-api';
import {
    AbortMultipartUploadCommand,
    CompleteMultipartUploadCommand,
    CreateMultipartUploadCommand,
    PutObjectCommand,
    type PutObjectOutput,
    S3Client,
    type S3ClientConfig,
    UploadPartCommand,
    type UploadPartOutput,
} from '@aws-sdk/client-s3';

/**
 * This is a class imitating S3 client which proxies specific commands through backend.
 * The file upload is done directly from the browser to the S3 bucket.
 */
export class S3ClientProxy extends S3Client {
    protected client: S3ClientProxyApi;

    constructor(options: S3ClientConfig) {
        super(options);
        this.client = new S3ClientProxyApi({
            baseUrl: `${config.apiProxy.host}${config.apiProxy.path}`,
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async send(command: any, ...args: any[]) {
        if (command instanceof AbortMultipartUploadCommand) {
            return this.client.abortMultipartUpload(command.input);
        }
        if (command instanceof CreateMultipartUploadCommand) {
            return this.client.createMultipartUpload(command.input);
        }
        if (command instanceof CompleteMultipartUploadCommand) {
            return this.client.completeMultipartUpload(command.input);
        }
        if (command instanceof PutObjectCommand) {
            return this.putObject(command);
        }
        if (command instanceof UploadPartCommand) {
            return this.uploadPart(command);
        }
        return super.send(command, ...args);
    }

    private async putObject(command: PutObjectCommand): Promise<PutObjectOutput> {
        const { Bucket, Body, Key } = command.input;
        const signedUrl = await this.client.signPutObject({ Bucket, Key });
        const response = await this.client.upload(signedUrl, Body);
        return {
            ETag: response.headers.get('ETag'),
        };
    }

    private async uploadPart(command: UploadPartCommand): Promise<UploadPartOutput> {
        const { Bucket, Body, Key, PartNumber, UploadId } = command.input;
        const signedUrl = await this.client.signUploadPart({ Bucket, Key, PartNumber, UploadId });
        const response = await this.client.upload(signedUrl, Body);
        return {
            ETag: response.headers.get('ETag'),
        };
    }
}
