import authToken from 'models/authToken';

/**
 *
 * @param {RequestInit} [init=]
 * @returns {RequestInit}
 */
export default function preRequest(init) {
    const token = authToken.get();

    return (
        token &&
        token.raw && {
            ...init,
            headers: {
                ...init.headers,
                Authorization: `Bearer ${token.raw}`,
            },
        }
    );
}
