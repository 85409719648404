// Temporary flag for enabling podme integration
const getPodmeIntegrationFlag = (): boolean => {
    try {
        return window.localStorage.getItem('enablePodmeIntegration') === 'true';
    } catch (error) {
        return false;
    }
};

export const isPodmeIntegrationEnabled = getPodmeIntegrationFlag();

export function isLinodeUploadEnabled(provider: string) {
    return ['ap', 'bt', 'sa'].includes(provider);
}
