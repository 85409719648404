import config from 'config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { reportMessageToSentry } from 'lib/error';
import { isNumeric } from 'lib/number';
import type { RootState } from 'store';
import { selectEngagementStatsApiKey } from './admin-bff-sdk/selectors';

interface CompletionDataPoint {
    desktop: number;
    mobile: number;
    position: number;
    tablet: number;
    total?: number;
}

export interface QueryArguments {
    assetId: number;
}

export type CompletionData = CompletionDataPoint[];

export const DATA_INTERVAL = 10;
export const DATA_INTERVAL_THRESHOLD = 20;

const isCompletionData = (response: unknown): response is CompletionData => {
    if (Array.isArray(response)) {
        return response.every(({ position, desktop, mobile, tablet }) => {
            const isDataAvailable = isNumeric(desktop) || isNumeric(mobile) || isNumeric(tablet);
            return isNumeric(position) && isDataAvailable;
        });
    }
    return false;
};

const withTotal = (data: CompletionData): CompletionData => {
    return data.map((point) => {
        const total = point.desktop + point.mobile + point.tablet;
        return { ...point, total };
    });
};

export const videoCompletionApi = createApi({
    reducerPath: 'videoCompletionApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config.videoCompletionApi.url,
        prepareHeaders: (headers, api) => {
            const state = api.getState() as RootState;

            const engagementKeyFromApi = selectEngagementStatsApiKey(state, state.newsroom);

            if (engagementKeyFromApi) {
                headers.set('Authorization', `Key ${engagementKeyFromApi}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        getCompletionData: builder.query<CompletionData, QueryArguments>({
            query: ({ assetId }) => `completion_data?video_id=${assetId}`,
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    const errorMessage = 'Unable to fetch video completion data.';
                    reportMessageToSentry({
                        message: errorMessage,
                        extras: {
                            error,
                        },
                    });
                }
            },
            transformResponse: (response) => {
                if (!response || !isCompletionData(response)) {
                    return [];
                }

                const transformed =
                    response.length > DATA_INTERVAL_THRESHOLD
                        ? response.filter(({ position }) => position % DATA_INTERVAL === 0)
                        : response;

                // if no starting point create fake one to preserve continuity
                if (transformed[0]?.position !== 0) {
                    transformed.unshift({ ...response[0], position: 0 });
                }

                return withTotal(transformed);
            },
        }),
    }),
});

export const { useGetCompletionDataQuery } = videoCompletionApi;
