import { createSelector } from '@reduxjs/toolkit';
import config from 'config';
import { prepareUsersNewsrooms } from 'components/settings/UserAccessManager/helpers';
import { RootState } from 'store';
import { adminBffSdk, api } from '.';

export function makeUserNewsroomSelector(email: string) {
    return createSelector([api.endpoints.GetUsers.select({ query: email })], ({ data }) => {
        const roles = data?.getUsers.at(0)?.roles;
        if (!roles) {
            return undefined;
        }
        return prepareUsersNewsrooms(roles, Object.keys(config.newsrooms));
    });
}

export const selectEngagementStatsApiKey = createSelector(
    [(state: RootState) => state, (state: RootState, provider: string) => provider],
    (state, provider) =>
        // @ts-expect-error - rtk query does not see tags from injected/enhanced endpoints and throws error, even though it is correct
        adminBffSdk.endpoints.GetEngagementStatsApiKey.select({ provider })(state)?.data?.getEngagementStatsApiKey
            ?.value
);
