import config from 'config';
import { useWatch, Controller } from 'react-hook-form';
import { Button } from '@schibsted-svp/react-ui';
import type { Control, UseFormRegister } from 'react-hook-form';
import type { Asset } from '@schibsted-svp/svp-api-types';
import { isAudio, isEngagementChartAvailable } from 'models/asset';
import AssetTabs, { AssetTab } from 'components/video/AssetTabs';
import Engagement from 'components/video/Engagement';
import Clips from 'components/video/Clips';
import { PreviewRHF } from 'components/video/VideoPoster/CustomPreview/Preview';
import NextVideo from './NextVideo';
import { Midrolls } from '../fields/Midrolls';
import { Poster } from './Poster';
import { Highlights } from '../fields/Highlights/Highlights';
import type { FormValues } from '../types';

import css from './FormTabs.module.scss';

type FormTabsProps = {
    asset: Asset;
    control: Control<FormValues>;
    isSubmitting: boolean;
    register: UseFormRegister<FormValues>;
};

export function FormTabs({ asset, control, isSubmitting, register }: FormTabsProps) {
    const disableNextVideo = useWatch({ control, name: 'additional.metadata.disableNextVideo' });

    return (
        <AssetTabs>
            <AssetTab label="Images" target="images">
                <Poster asset={asset} control={control} register={register} />
            </AssetTab>
            {!isAudio(asset) ? (
                <AssetTab label="Previews" target="previews">
                    <PreviewRHF asset={asset} control={control} />
                </AssetTab>
            ) : null}
            <AssetTab label={`Next ${asset.assetType}`} target="next-video">
                <NextVideo
                    id={asset.id}
                    provider={asset.provider}
                    disableNextVideo={disableNextVideo}
                    disableNextVideoRhfCheckbox={
                        <Controller
                            name="additional.metadata.disableNextVideo"
                            control={control}
                            render={({ field: { onChange } }) => (
                                <Button
                                    onClick={() => onChange(true)}
                                    type="button"
                                    size="small"
                                    variant="standard"
                                    className={css.disableNextVideoCheckbox}
                                >
                                    Disable
                                </Button>
                            )}
                        />
                    }
                    enableNextVideoRhfCheckbox={
                        <Controller
                            name="additional.metadata.disableNextVideo"
                            control={control}
                            render={({ field: { onChange } }) => (
                                <Button
                                    onClick={() => onChange(false)}
                                    type="button"
                                    size="small"
                                    className={css.enableNextVideoCheckbox}
                                >
                                    Enable
                                </Button>
                            )}
                        />
                    }
                />
            </AssetTab>
            <AssetTab label="Highlights" target="highlights">
                <Highlights
                    id={asset.id}
                    provider={asset.provider}
                    duration={asset.duration}
                    isSubmitting={isSubmitting}
                    control={control}
                />
            </AssetTab>
            <AssetTab label="Trim/Clips" target="clips">
                <Clips id={asset.id} provider={asset.provider} rhfControl={control} rhfIsSubmitting={isSubmitting} />
            </AssetTab>
            <AssetTab label="Ad breaks" target="midrolls">
                <Midrolls asset={asset} control={control} />
            </AssetTab>
            {isEngagementChartAvailable(asset) ? (
                <AssetTab label="Engagement" target="engagement">
                    <Engagement assetId={asset.id} provider={asset.provider} />
                </AssetTab>
            ) : (
                <AssetTab
                    label="Engagement"
                    target={`${config.jwDashboard}?key=${asset.additional.externalId}&spotlight=engagement&tab=analytics`}
                />
            )}
        </AssetTabs>
    );
}
