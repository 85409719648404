import { Upload } from '@aws-sdk/lib-storage';
import config from 'config';
import { SECOND } from 'lib/time';
import { S3ClientProxy } from './s3client-proxy';
import { IFileUploader, UploadData } from './types';
import { type EventHandlers, FileUploaderEmitter } from './file-uploader-emitter';
import { generateKey } from './utils';

function initUpload(key: string, file: File) {
    return new Upload({
        client: new S3ClientProxy({
            region: config.linode.region,
        }),
        params: {
            Bucket: config.linode.bucket,
            Key: key,
            Body: file,
        },
    });
}

function getFilenameWithBucket(filename: string) {
    return `${config.linode.bucket}/${filename}`;
}

export class AWSFileUploader extends FileUploaderEmitter implements IFileUploader {
    protected uploads: Map<string, Upload>;

    constructor(eventHandlers: EventHandlers) {
        super(eventHandlers);
        this.uploads = new Map();
    }

    async uploadFile(params: UploadData) {
        const filename = generateKey(params);

        try {
            const upload = initUpload(filename, params.file);
            const startTime = Date.now();

            upload.on('httpUploadProgress', ({ loaded, total }) => {
                const delta = (Date.now() - startTime) / SECOND;
                const avgSpeed = loaded / delta;
                const remainingSize = total - loaded;

                this.onProgress({
                    ...params,
                    filename: getFilenameWithBucket(filename),
                    progress: Math.floor((loaded / total) * 100),
                    secondsLeft: Math.round(remainingSize / avgSpeed),
                });
            });
            this.uploads.set(filename, upload);

            await upload.done();
            this.uploads.delete(filename);

            this.onComplete({
                ...params,
                object: filename,
            });

            return filename;
        } catch (error) {
            this.uploads.delete(filename);
            this.onError({
                ...params,
                message: error instanceof Error ? error.message : String(error),
            });
            throw error;
        }
    }

    async cancelUploadFile(filename: string) {
        return this.uploads.get(filename)?.abort();
    }

    async uploadFileWithoutProgress({ file, name }: { file: File; name: string }) {
        const filename = getFilenameWithBucket(name);

        try {
            const upload = initUpload(name, file);
            this.uploads.set(filename, upload);

            await upload.done();
            this.uploads.delete(filename);

            return name;
        } catch (error) {
            this.uploads.delete(filename);
            throw error;
        }
    }
}
