import { lazy, Suspense } from 'react';
import { Spinner, InputError } from '@schibsted-svp/react-ui';
import { useGetCompletionDataQuery } from 'services/video-completion';
import { useGetEngagementStatsApiKeyQuery } from 'services/admin-bff-sdk';
import css from './Engagement.module.scss';

const Chart = lazy(() => import(/* webpackChunkName: 'engagementChart' */ './Chart'));

interface DataErrorProps {
    children: string;
}

interface EngagementChartProps {
    assetId: number;
    provider: string;
}

function SpinnerWrapper() {
    return <Spinner containerClassName={css.spinner} />;
}

function DataError({ children }: DataErrorProps) {
    return (
        <div className={css.error}>
            <InputError>{children}</InputError>
            <InputError>Please contact VG Media Insight team.</InputError>
        </div>
    );
}

function EngagementChart({ assetId, provider }: EngagementChartProps) {
    const {
        error: engagementKeyFromApiError,
        isError: isEngagementKeyFromApiError,
        isSuccess: isEngagementKeyFromApiSuccess,
    } = useGetEngagementStatsApiKeyQuery({ provider });

    const {
        data: completionData,
        error: completionDataError,
        isError: isCompletionDataError,
        isLoading: isCompletionDataLoading,
    } = useGetCompletionDataQuery({ assetId }, { skip: !isEngagementKeyFromApiSuccess });

    if (isCompletionDataLoading || !isEngagementKeyFromApiSuccess) {
        return <SpinnerWrapper />;
    }

    if (isEngagementKeyFromApiError || isCompletionDataError) {
        const error = isEngagementKeyFromApiError ? engagementKeyFromApiError : completionDataError;

        const errorStatus = ('originalStatus' in error && error.originalStatus) || ('status' in error && error.status);

        if (errorStatus === 403) {
            return <DataError>You are not authorized to see engagement data.</DataError>;
        }

        if (errorStatus === 404) {
            return <DataError>Engagement data for this video is not available yet.</DataError>;
        }

        return <DataError>Failed to fetch engagement data.</DataError>;
    }

    return (
        <Suspense fallback={<SpinnerWrapper />}>
            <Chart data={completionData} />
        </Suspense>
    );
}

export default EngagementChart;
