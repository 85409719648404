import Client from 'services/client';
import preRequest from 'services/api-client/pre-request';
import type {
    AbortMultipartUploadCommandInput,
    AbortMultipartUploadCommandOutput,
    CompleteMultipartUploadCommandInput,
    CompleteMultipartUploadCommandOutput,
    CreateMultipartUploadCommandInput,
    CreateMultipartUploadCommandOutput,
    PutObjectCommandInput,
    UploadPartCommandInput,
} from '@aws-sdk/client-s3';

type InputFrom<T> = { [K in keyof T]: T[K] };

type SignResponse = { signedUrl: string };

export class S3ClientProxyApi extends Client {
    protected preRequest = preRequest;

    async abortMultipartUpload(
        input: InputFrom<AbortMultipartUploadCommandInput>
    ): Promise<AbortMultipartUploadCommandOutput> {
        return this.request('v1/aws/upload/abort', 'post', {
            json: input,
        });
    }

    async createMultipartUpload(
        input: InputFrom<CreateMultipartUploadCommandInput>
    ): Promise<CreateMultipartUploadCommandOutput> {
        return this.request('v1/aws/upload/create', 'post', {
            json: input,
        });
    }

    async completeMultipartUpload(
        input: InputFrom<CompleteMultipartUploadCommandInput>
    ): Promise<CompleteMultipartUploadCommandOutput> {
        return this.request('v1/aws/upload/complete', 'post', {
            json: input,
        });
    }

    async signPutObject(input: InputFrom<PutObjectCommandInput>): Promise<string> {
        const response: SignResponse = await this.request('v1/aws/upload/sign', 'post', {
            json: input,
        });
        return response.signedUrl;
    }

    async signUploadPart(input: InputFrom<UploadPartCommandInput>): Promise<string> {
        const response: SignResponse = await this.request('v1/aws/upload/sign', 'post', {
            json: input,
        });
        return response.signedUrl;
    }

    // eslint-disable-next-line class-methods-use-this
    async upload(url: string, body: PutObjectCommandInput['Body']): Promise<Response> {
        return fetch(url, {
            method: 'PUT',
            headers: new Headers({
                'Content-Type': 'application/octet-stream',
            }),
            body: body as Uint8Array,
        });
    }
}
