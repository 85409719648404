import { get } from 'lodash';
import { merge } from 'lib/object';
import defaultConfig from './default';
import development from './development';
import production from './production';
import testing from './testing';

let config = defaultConfig; // eslint-disable-line

declare const __ADMIN_ENV__: string;

const ADMIN_ENV = __ADMIN_ENV__;

if (ADMIN_ENV !== 'production' && ADMIN_ENV !== 'development' && ADMIN_ENV !== 'staging' && ADMIN_ENV !== 'testing') {
    // eslint-disable-next-line no-console
    console.info('Using default configuration');
}

if (ADMIN_ENV === 'staging') {
    if (JSON.parse(localStorage.getItem('svpEnableDevelopmentConfiguration'))) {
        // eslint-disable-next-line no-console
        console.info(
            'Using development configuration because svpEnableDevelopmentConfiguration flag in localStorage is enabled'
        );
        config = merge(config, development);
    }
}

if (ADMIN_ENV === 'development') {
    if (JSON.parse(localStorage.getItem('svpEnableStagingConfiguration'))) {
        // eslint-disable-next-line no-console
        console.info(
            'Using staging configuration because svpEnableStagingConfiguration flag in localStorage is enabled'
        );
    } else {
        // eslint-disable-next-line no-console
        console.info('Using dev configuration');
        config = merge(config, development);
    }
}

if (ADMIN_ENV === 'production') {
    // eslint-disable-next-line no-console
    console.info('Using prod configuration');
    config = merge(config, production);
}

if (ADMIN_ENV === 'testing') {
    config = merge(config, testing);
}

const getDefaultCategoryFor = (newsroom: string): number => config.asset.defaultCategory[newsroom] || 1;
const getNewsroomConfig = (provider: string) =>
    merge(config.defaultNewsroomConfig, config.newsroomsConfig[provider] || {});
const getNewsroomLivePaywallAccess = (newsroom: string) =>
    get(getNewsroomConfig(newsroom), 'live.defaultPaywallAccess');

export { getNewsroomConfig, getDefaultCategoryFor, getNewsroomLivePaywallAccess };
export default config;
