import Evaporate from 'evaporate';
import config from 'config';
import token from 'models/authToken';
import { type EventHandlers, FileUploaderEmitter } from './file-uploader-emitter';
import type { IFileUploader, UploadData, UploadWithoutProgressData } from './types';
import { generateKey } from './utils';

async function customAuthMethod(_signParams: object, _signHeaders: object, stringToSign: string) {
    const url = new URL('/api/v1/aws/sign', config.apiProxy.host);
    url.searchParams.set('to_sign', decodeURIComponent(stringToSign));

    const response = await fetch(url, {
        headers: {
            Authorization: `Bearer ${token.get().raw}`,
        },
    });

    if (response.status === 200) {
        return response.text();
    }

    throw new Error(response.statusText);
}

export class EvaporateFileUploader extends FileUploaderEmitter implements IFileUploader {
    evaporate: Evaporate;

    constructor(eventHandlers: EventHandlers) {
        super(eventHandlers);

        this.evaporate = new Evaporate({
            ...config.evaporate,
            customAuthMethod,
            aws_key: config.aws.key,
            aws_url: `https://${config.aws.bucket}.s3.vgnett.no`,
            bucket: config.aws.bucket,
        });
    }

    uploadFile({ id, file, provider, ...rest }: UploadData) {
        let completed = false;
        const filename = generateKey({ id, file, provider, ...rest });
        return this.evaporate.add({
            progress: (progress, stats) => {
                if (completed) {
                    return;
                }

                const { secondsLeft } = stats;

                this.onProgress({
                    ...rest,
                    id,
                    file,
                    provider,
                    filename: `${config.aws.bucket}/${filename}`,
                    progress: Math.floor(progress * 100),
                    secondsLeft,
                });
            },
            complete: (xhr, awsObjectKey, stats) => {
                completed = true;
                this.onComplete({
                    ...rest,
                    id,
                    file,
                    provider,
                    object: awsObjectKey,
                    stats,
                });
            },
            error: (message) => {
                this.onError({
                    ...rest,
                    id,
                    file,
                    provider,
                    message,
                });
            },
            name: filename,
            file,
        });
    }

    async cancelUploadFile(filename: string) {
        await this.evaporate.cancel(filename);
    }

    uploadFileWithoutProgress({ file, name }: UploadWithoutProgressData) {
        return this.evaporate.add({
            name,
            file,
        });
    }
}
